import React, {useState, useRef, useEffect} from "react";
import {useNavigate } from "react-router-dom";
import './home.css';
import Generic from '../templates/generic/generic';
import Header from "../common/Header/header";

const Home = () => {
    const websiteURL = React.createRef();
    const navigate = useNavigate();
    const redirect_uri = encodeURI(`${process.env.REACT_APP_CALLBACK_URL}auth/`);
    const [helpOverlay, setHelpOverlay] = useState({
        show: false,
        totalSlides: 2,
        slide: 1
    });

    const [chatGpt, setChatGpt] = useState({
        useChatGpt: false
    });

    const [authUrl, setAuthUrl] = useState({
        isLoading: true,
        url: undefined
    });

    const formRef = useRef(null);
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        formRef.current.requestSubmit();
    }, [chatGpt]);

    useEffect(() => {
        if (!authUrl.isLoading) {
            let whispUserAuth = sessionStorage.getItem('whispUserAuth');
            if (whispUserAuth === undefined || whispUserAuth === null) {
                window.location.href = authUrl.url;
            } else {
                localStorage.setItem('useChatGPT', chatGpt.useChatGpt);
            }
        }
    }, [authUrl]);

    useEffect(() => {
        getAuthUrl();
    }, []);

    function getAuthUrl() {
        let postData = {
            "CallbackUrl": redirect_uri
        }
        fetch(`${process.env.REACT_APP_API_URL}auth-url`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "omit",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Origin": process.env.REACT_APP_CALLBACK_URL,
                "Access-Control-Request-Method": "POST"
            },
            redirect: "follow",
            referrer: "no-referrer",
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                }
            })
            .then(data => {

                if (data !== undefined) {
                    setAuthUrl({...authUrl,
                        isLoading: false,
                        url: data.Url
                    });
                } else {
                    console.log(data);
                    console.log("errorType: " + data.ConnectionError);
                    console.log("errorMessage" + data.errorMessage);
                }

            })
            .catch(error => console.error(`Fetch Error =\n`, error));
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        const websiteURL = formData.get('websiteURL');

        localStorage.setItem('useChatGPT', chatGpt.useChatGpt);
        localStorage.setItem('websiteURL', websiteURL);

        if (chatGpt.useChatGpt) {
            navigate('/chatgpt/');
        } else {
            navigate('/article/');
        }

    }

    const handleHelpClick = () => {
        setHelpOverlay({
            show: true,
            slide: helpOverlay.slide,
            totalSlides: helpOverlay.totalSlides
        })
    }

    const handleCloseHelp = () => {
        setHelpOverlay({
            show: false,
            slide: helpOverlay.slide,
            totalSlides: helpOverlay.totalSlides
        })
    }

    const showNextSlide = () => {
        let newSlideNumber = helpOverlay.slide;
        if (newSlideNumber < helpOverlay.totalSlides) {
            newSlideNumber = newSlideNumber + 1;
        } else {
            newSlideNumber = 1;
        }
        setHelpOverlay({
            show: helpOverlay.show,
            slide: newSlideNumber,
            totalSlides: helpOverlay.totalSlides
        })
    }

    const showPrevSlide = () => {
        let newSlideNumber = helpOverlay.slide;
        if (newSlideNumber > 1) {
            newSlideNumber = newSlideNumber - 1;
        } else {
            newSlideNumber = 1;
        }
        setHelpOverlay({
            show: helpOverlay.show,
            slide: newSlideNumber,
            totalSlides: helpOverlay.totalSlides
        })
    }

    const handleUseChatGPT = (event) => {
        setChatGpt({
            useChatGpt: true
        })
    }

    function userLoginStatus() {
        let whispUserAuth = sessionStorage.getItem('whispUserAuth');
        let loginStatus = false;
        if (whispUserAuth !== undefined && whispUserAuth !== null) {
          // user is logged in
            loginStatus = true;
        }
        return loginStatus;
    }

        return (
            <>

                <Generic>
                    <div className="home-component">
                        <div className="left-column">
                            <Header/>

                            <form onSubmit={onFormSubmit} ref={formRef} >
                                <textarea name="websiteURL" id="websiteURL" cols="30" rows="10"
                                          placeholder={'Paste a URL or text here or upload/drag-&-drop your document'}
                                          className={(helpOverlay.slide === 1)?"help-item":""}></textarea>
                                <div className="action-buttons">
                                    {(userLoginStatus())? (
                                        <>
                                            <div className="btn-blue" onClick={handleUseChatGPT}>Check with ChatGPT</div>
                                            <button className={(helpOverlay.slide === 2)?"btn-blue help-item":"btn-blue"}>Check URL</button>
                                        </>
                                        ) : (
                                            <div className={"btn-blue"} onClick={() => { navigate('/account/'); }}>
                                                Please visit the account page login to access Whisp.
                                            </div>
                                        )}
                                </div>
                            </form>


                        </div>
                        <div className="right-column">
                            <video autoPlay muted playsInline loop
                                   poster="/images/whisp-logo-home-page.png">
                                <source src="/videos/Video-With-Black-Fade-1-edited.m4v" type="video/mp4"/>
                            </video>
                        </div>
                    </div>
                    <div className="how-to">
                        <div className="help" onClick={handleHelpClick}>
                            <img src="/images/Icon_Help_White.svg" alt="Help" width="28" height="28" loading="lazy"/>
                        </div>
                        <div className="new">
                            <img src="/images/icon_new_search_white.svg" alt="New Search" width="28" height="28"
                                 loading="lazy"/>
                        </div>
                    </div>
                </Generic>

                {(helpOverlay.show)? (
                    <div className="help-overlay">
                        <div className="top-bar">
                            <img src="/images/Icon_Close_white.svg" alt="Close" onClick={handleCloseHelp} />
                        </div>
                        <div className="image-container" onClick={showNextSlide}>
                            {(helpOverlay.slide === 1)? (
                            <img src="/images/help-text-box.png" alt="Help overlay 1" className="help-overlay-1"/>
                            ) : (
                            <img src="/images/help-button.png" alt="Help overlay 2" className="help-overlay-2"/>
                            )}
                        </div>
                        <div className="bottom-bar">
                            <div className="slide-controls">
                                <div className="prev" onClick={showPrevSlide}>
                                    {(helpOverlay.slide > 1)? (
                                    <img src="/images/arrow-small-back-white.svg" width="12" height="20" loading="lazy" alt="Back arrow" />
                                    ) : (
                                        <img src="/images/arrow-small-back-white-disabled.svg" width="12" height="20" loading="lazy" alt="Back arrow disabled" />
                                    )}
                                </div>
                                <div className="circles">
                                    <div className={(helpOverlay.slide === 1)? 'circle active':'circle'}></div>
                                    <div className={(helpOverlay.slide === 2)? 'circle active':'circle'}></div>
                                </div>
                                <div className="next" onClick={showNextSlide}>
                                    {(helpOverlay.slide < helpOverlay.totalSlides)? (
                                        <img src="/images/arrow-small-next-white.svg" width="12" height="20" loading="lazy" alt="Next arrow" />
                                    ) : (
                                        <img src="/images/arrow_small_next_disabled.svg" width="12" height="20" loading="lazy" alt="Next arrow disabled" />
                                    )}
                                </div>
                            </div>
                            <div className="btn-outline" onClick={handleCloseHelp}>Got it!</div>
                        </div>
                    </div>
                ) : null }

            </>
        )
}
export default Home;
