import React from "react";
import './factcheck.css';
class FactCheck extends React.Component {
    constructor(props) {
        super(props);
        this.handleEvidenceClick = this.handleEvidenceClick.bind(this);
        this.handleVerdictCheck = this.handleVerdictCheck.bind(this);
        this.handleCertaintyCheck = this.handleCertaintyCheck.bind(this);
        this.handleClaimsCheck = this.handleClaimsCheck.bind(this);
        this.totalPositiveClaims = this.totalPositiveClaims.bind(this);
        this.totalNegativeClaims = this.totalNegativeClaims.bind(this);
        this.handleOriginalSentenceUnderline = this.handleOriginalSentenceUnderline.bind(this);
        this.handleClaimUnderline = this.handleClaimUnderline.bind(this);
        this.getCertaintyLevel = this.getCertaintyLevel.bind(this);
    }

    handleEvidenceClick (selectedClaim) {
        let selectedClaimIndex = this.props.factCheckItem.claims.findIndex( (claim) => claim.text ===  selectedClaim.text );
        this.props.handleEvidenceClick(selectedClaim, selectedClaimIndex);
    }
    handleVerdictCheck (claim) {
        if (claim.evidence !== null) {
            return (claim.evidence.entail.length > claim.evidence.refute.length);
        } else {
            return false;
        }
    }
    handleCertaintyCheck (claim) {
        return (claim.evidence.entail.length > claim.evidence.refute.length);
    }
    handleClaimsCheck (claim) {
        return (claim.evidence.entail.length > claim.evidence.refute.length);
    }

    totalPositiveClaims() {
        if (this.props.factCheckItem.claims !== null) {
            let postitiveClaims = this.props.factCheckItem.claims.filter(claim => {
                if (claim.evidence !== null && claim.evidence.n_entail > claim.evidence.n_refute) {
                    return claim;
                } else {
                    return null;
                }
            }).length;

            if (!isNaN(postitiveClaims)) {
                return postitiveClaims;
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    }

    totalNegativeClaims() {
        if (this.props.factCheckItem.claims) {
            let negativeClaims = this.props.factCheckItem.claims.filter(claim => {
                if (claim.evidence !== null && claim.evidence.n_entail < claim.evidence.n_refute) {
                    return claim;
                } else {
                    return null;
                }
            }).length;

            if (!isNaN(negativeClaims)) {
                return negativeClaims;
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    }

    handleOriginalSentenceUnderline() {
        let sentenceClasses = " original-sentence ";
        if (this.props.factCheckItem.checkworthy !== undefined && this.props.factCheckItem.checkworthy.score) {

            // Check if CFS
            if ( (this.props.factCheckItem.checkworthy.score.CFS * 100) > 50 ) {

                // Calculate how many Positive and Negative claims based on the evidence entail,refute
                let totalPositiveClaims = this.props.factCheckItem.claims.filter(claim => {
                    if (claim.evidence !== null && claim.evidence.n_entail > claim.evidence.n_refute) {
                        return claim;
                    } else {
                        return null;
                    }
                }).length;

                let totalNegativeClaims = this.props.factCheckItem.claims.filter(claim => {
                    if (claim.evidence !== null && claim.evidence.n_entail < claim.evidence.n_refute) {
                        return claim;
                    } else {
                        return null;
                    }
                }).length;

                // mostly entail claims
                if (totalPositiveClaims > totalNegativeClaims) {
                    sentenceClasses += " mostly-entail ";
                }
                // mostly refute claims
                if (totalPositiveClaims < totalNegativeClaims) {
                    sentenceClasses += " mostly-refute ";
                }

            }
        }
        return sentenceClasses;
    }

    handleClaimUnderline(claim) {
        let sentenceClasses = " claim-text ";
        if (claim.checkworthy !== undefined && claim.checkworthy !== null && claim.checkworthy.score) {
            // Check if CFS
            if ( (claim.checkworthy.score.CFS * 100) > 50 ) {

                if (claim.evidence !== null) {
                    let totalPositiveClaims = claim.evidence.n_entail;

                    let totalNegativeClaims = claim.evidence.n_refute;

                    // mostly entail claims
                    if (totalPositiveClaims > totalNegativeClaims) {
                        sentenceClasses += " mostly-entail ";
                    }
                    // mostly refute claims
                    if (totalPositiveClaims < totalNegativeClaims) {
                        sentenceClasses += " mostly-refute ";
                    }
                }
            }
        }
        return sentenceClasses;
    }

    getCertaintyLevel(claim) {
        let certaintyLevel = "Low";
        let totalPositiveClaims = 0;
        let totalNegativeClaims = 0;

        // if no claim passed through then use the normal functions for the sentence
        if ( claim === null ) {
            totalPositiveClaims = this.totalPositiveClaims();
            totalNegativeClaims = this.totalNegativeClaims();
        } else {
            if (claim.evidence !== null) {
                totalPositiveClaims = claim.evidence.n_entail;
                totalNegativeClaims = claim.evidence.n_refute;
            }
        }

        // check if there are both positive and negative
        if ( totalPositiveClaims > 0 && totalNegativeClaims > 0) {

            let percentage = 0;
            if (totalPositiveClaims > totalNegativeClaims) {
                percentage = ((totalPositiveClaims / (totalNegativeClaims + totalPositiveClaims)) * 100);
            } else {
                percentage = (totalNegativeClaims / (totalNegativeClaims + totalPositiveClaims)) * 100;
            }

            if (percentage > 76) {
                certaintyLevel = "High";
            } else if (percentage > 61) {
                certaintyLevel = "Medium";
            }
            // defaults to low if below 61%
        } else if ( totalPositiveClaims > 0 || totalNegativeClaims > 0 ){
            // gets set to "High" if either one of them is greater than 1 because there is only one type
            certaintyLevel = "High";
        }

        return certaintyLevel;
    }

    render() {
        let claimCounter = 1;
        return (
            <div className="factcheck-component">
                <div className="claim-data">
                    <div className="">
                        <div className="original-sentence-container">

                            <div className={( (this.totalPositiveClaims() + this.totalNegativeClaims()) > 0 )? 'sentence' : 'sentence wide'}>

                                {( (this.totalPositiveClaims() + this.totalNegativeClaims()) > 0 )? (
                                <>
                                    <strong>Original Sentence</strong> <br />
                                    <span className={ this.handleOriginalSentenceUnderline() }>{
                                        (this.props.chatGPTMode)? this.props.factCheckItem.content.content : this.props.factCheckItem.text
                                    }</span><br />
                                    <div className="summary">
                                        <div><span className="summary-title">Verdict:</span><br /><span>
                                            { (this.totalPositiveClaims() > this.totalNegativeClaims())? 'Agree' : (
                                                (this.totalPositiveClaims() < this.totalNegativeClaims())? 'Disagree' : 'Undetermined'
                                            ) }
                                        </span></div>
                                        <div><span className="summary-title">Certainty level:</span><br />
                                                <span>{this.getCertaintyLevel(null)}</span>
                                        </div>
                                        <div>
                                            {(this.totalPositiveClaims() > 0 || this.totalNegativeClaims() > 0)? (
                                                <span>
                                                    <span className="summary-title">Claims:</span><br />
                                                    {(this.totalPositiveClaims() > this.totalNegativeClaims())? (
                                                        <span>{this.totalPositiveClaims()}/{ this.totalNegativeClaims() + this.totalPositiveClaims() } Agree</span>
                                                    ): (
                                                        <span>{this.totalNegativeClaims()}/{ this.totalNegativeClaims() + this.totalPositiveClaims() } Disagree</span>
                                                    )}
                                                </span>
                                            ) : null}
                                        </div>
                                    </div>
                                </>
                                ) : (
                                    <div className="not-checked">
                                        <div className="message">
                                        {( this.props.factCheckItem.checkworthy !== undefined && this.props.factCheckItem.checkworthy !== null
                                            && this.props.factCheckItem.checkworthy.score !== undefined && this.props.factCheckItem.checkworthy.score !== null
                                            && this.props.factCheckItem.checkworthy.score.NFS > this.props.factCheckItem.checkworthy.score.UFS)? (
                                        <>
                                            This sentence has not been checked because it is a non-factual statement so cannot be verified
                                        </>
                                        ) : (
                                        <>
                                            No Evidence: Whisp was unable to substantiate the claims in this sentence
                                        </>
                                        )}
                                        </div>
                                        <div className="ratings">
                                            {(this.props.factCheckItem.checkworthy !== undefined && this.props.factCheckItem.checkworthy !== null
                                                && this.props.factCheckItem.checkworthy.score !== undefined && this.props.factCheckItem.checkworthy.score !== null)? (
                                                    <>
                                                        Non Factual {(this.props.factCheckItem.checkworthy.score.NFS * 100).toFixed(0)}%  Uncheckable {(this.props.factCheckItem.checkworthy.score.UFS * 100).toFixed(0)}%  Checkable {(this.props.factCheckItem.checkworthy.score.CFS * 100).toFixed(0)}%
                                                    </>
                                                ) : null}
                                        </div>
                                    </div>
                                )}

                            </div>
                            {((this.totalPositiveClaims() + this.totalNegativeClaims()) > 0)? (
                            <div className="progress-container">
                                {(this.totalPositiveClaims() > this.totalNegativeClaims())? (
                                    <div className="progress-bar" style={{ 'background' : 'radial-gradient(closest-side, #1F2937 59%, transparent 60% 100%), conic-gradient(#7cde82 calc(' + ( (this.totalPositiveClaims() / (this.totalNegativeClaims() + this.totalPositiveClaims())) * 100) + ' * 1%), #FE735A 0)' }}>
                                        <progress className="loading-circle-cfs" value={(this.totalPositiveClaims() / (this.totalNegativeClaims() + this.totalPositiveClaims())) * 100} min="0" max="100">{(this.totalPositiveClaims() / (this.totalNegativeClaims() + this.totalPositiveClaims())) * 100}%</progress>
                                    </div>
                                ) : (
                                    <div className="progress-bar" style={{ 'background' : 'radial-gradient(closest-side, #1F2937 59%, transparent 60% 100%), conic-gradient(#FE735A calc(' + ( (this.totalNegativeClaims() / (this.totalNegativeClaims() + this.totalPositiveClaims())) * 100) + ' * 1%), #7cde82 0)' }}>
                                        <progress className="loading-circle-cfs" value={(this.totalNegativeClaims() / (this.totalNegativeClaims() + this.totalPositiveClaims())) * 100} min="0" max="100">{(this.totalNegativeClaims() / (this.totalNegativeClaims() + this.totalPositiveClaims())) * 100}%</progress>
                                    </div>
                                )}
                            </div>
                            ):null}
                        </div>

                            { (this.props.factCheckItem.claims !== null && this.props.factCheckItem.claims.length > 0)? (
                            <div className="claims">
                                <div className="claims-title">Extracted Claims:</div>
                                {this.props.factCheckItem.claims.map(claim => (
                                    <div className="claim" key={claim.text}>
                                        <img className="claim-line-icon" src="/images/line-claim-susbset.svg" alt="Line claim subset icon" height="31" width="20" loading="lazy" />
                                        <div className="column-left">
                                            <div className="claim-counter">Claim {claimCounter++} of {this.props.factCheckItem.claims.length}:</div>
                                            <div className={this.handleClaimUnderline(claim)}>
                                                <span>{claim.text}</span>
                                            </div>
                                            <div className="claim-summary">
                                                <div>
                                                    <span className="cs-heading">Verdict:</span><br />
                                                    <span className="cs-value">{ ( this.handleVerdictCheck(claim) )? 'Agree' : (
                                                        ( (claim.evidence !== null) && (claim.evidence.entail.length + claim.evidence.refute.length > 0 ) )? 'Disagree' : 'Undetermined'
                                                    ) }</span>
                                                </div>
                                                <div>
                                                    <span className="cs-heading">Certainty level:</span><br />
                                                    <span className="cs-value">
                                                        <span>{this.getCertaintyLevel(claim)}</span>
                                                    </span>
                                                </div>
                                                <div>
                                                        <span className="cs-heading">Sources:</span><br />
                                                    { (claim.evidence !== null && (claim.evidence.n_refute > 0 || claim.evidence.n_entail > 0 ))? (
                                                        <span className="cs-value pointer" onClick={() => this.handleEvidenceClick(claim)}>
                                                            { ( this.handleVerdictCheck(claim) )? (
                                                                <span>{claim.evidence.entail.length} / {(claim.evidence.refute.length + claim.evidence.entail.length)} Agree</span>
                                                            ) : (
                                                                <span>{claim.evidence.refute.length} / {(claim.evidence.refute.length + claim.evidence.entail.length)} Disagree</span>
                                                            ) }
                                                        </span>
                                                    ) : (
                                                        <span className="cs-value">No Sources</span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column-right">

                                            {(claim.evidence !== null && (claim.evidence.n_refute > 0 || claim.evidence.n_entail > 0 ))? (
                                            <div className="progress-container pointer" onClick={() => this.handleEvidenceClick(claim)}>
                                                <div className="progress-bar progress-cfs refute" style={{ 'background' : 'radial-gradient(closest-side, #1F2937 79%, transparent 82% 100%),' + 'conic-gradient(#FE735A calc(' + ((claim.evidence.refute.length / (claim.evidence.refute.length + claim.evidence.entail.length)) * 100) + ' * 1%), #616872 0)' }}>
                                                    <progress className="loading-circle-cfs" value={(claim.evidence.refute.length / (claim.evidence.refute.length + claim.evidence.entail.length)) * 100} min="0" max="100">{(claim.evidence.refute.length / (claim.evidence.refute.length + claim.evidence.entail.length)) * 100}%</progress>
                                                </div>
                                                <div className="progress-bar progress-nfs entail" style={{ 'background' : 'radial-gradient(closest-side, #1F2937 79%, transparent 82% 100%),' + 'conic-gradient(#5abd60 calc(' + ( (claim.evidence.entail.length / (claim.evidence.refute.length + claim.evidence.entail.length) ) * 100) + ' * 1%), #616872 0)' }}>
                                                    <progress className="loading-circle-cfs" value={(claim.evidence.entail.length / (claim.evidence.refute.length + claim.evidence.entail.length) ) * 100} min="0" max="100">{ (claim.evidence.entail.length / (claim.evidence.refute.length + claim.evidence.entail.length) ) * 100 }%</progress>
                                                </div>
                                            </div>
                                            ) : null}
                                            { (claim.evidence !== null && (claim.evidence.n_refute > 0 || claim.evidence.n_entail > 0 ))? (
                                                <>
                                                <div className="view-evidence" onClick={() => this.handleEvidenceClick(claim)} >
                                                    <img src="/images/icon-view-evidence-white.svg" width="22" height="22" loading="lazy" alt="View evidence icon" />
                                                    <span>View Evidence</span>
                                                </div>
                                                </>
                                            ) : null }
                                        </div>
                                    </div>
                                ))}
                            </div>
                            ) : null }
                    </div>
                </div>

            </div>
        )

    }
}
export default FactCheck;
