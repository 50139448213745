import React, {useEffect, useState} from "react";
import Internal from "../templates/internal/internal";
import LoadingPage from "../common/loadingpage";
import {useNavigate} from "react-router-dom";

const Auth = () => {
    const navigate = useNavigate();
    const queryParameters = new URLSearchParams(window.location.search)
    const grantCode = queryParameters.get("code");

    const [userAuth, setuserAuth] = useState({
        isLoading: true,
        id_token: undefined,
        access_token: undefined,
        refresh_token: undefined,
        expires_in: undefined,
        token_type: undefined
    });

    useEffect(() => {
        let whispUserAuth = sessionStorage.getItem('whispUserAuth');
        if (whispUserAuth === undefined || whispUserAuth === null) {
            if (grantCode !== null) {
                handleGrantCodeExchange();
            }
        } else {
            setuserAuth({...userAuth, whispUserAuth})
        }
    }, []);

    function handleGrantCodeExchange() {
        // Debugging
        //return;
        let postData = {
            grantCode: grantCode,
            CallbackUrl: `${process.env.REACT_APP_CALLBACK_URL}auth/`
        }

        fetch(`${process.env.REACT_APP_API_URL}tokens`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "omit",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Origin": process.env.REACT_APP_CALLBACK_URL,
                "Access-Control-Request-Method": "POST",
            },
            redirect: "follow",
            referrer: "no-referrer",
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                }
            })
            .then(data => {
                if (data.errorType === undefined) {
                    let authResult = {
                        isLoading: false,
                        id_token: data.id_token,
                        access_token: data.access_token,
                        refresh_token: data.refresh_token,
                        expires_in: data.expires_in,
                        token_type: data.token_type
                    }
                    setuserAuth({...userAuth, authResult });
                    sessionStorage.setItem('whispUserAuth', JSON.stringify(authResult));
                    navigate('/');
                } else {
                    console.log(data);
                    console.log("errorType: " + data.ConnectionError);
                    console.log("errorMessage" + data.errorMessage);
                }
            })
            .catch(error => console.error(`Fetch Error =\n`, error));
    }


    return (
        <>
            <Internal>
                <LoadingPage />
            </Internal>
        </>
    )
}
export default Auth;
