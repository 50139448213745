import React from "react";
import Api from '../../Api';
import './conversation.css';
import FactCheck from "./factcheck/factcheck";
import Evidence from "./evidence/evidence";
import Generic from '../templates/generic/generic';
import Header from "../common/Header/header";
import LoadingPage from "../common/loadingpage";
import {Link} from "react-router-dom";
class Conversation extends React.Component {
    constructor() {
        super();
        this.state = {
            isLoading: true,
            isFactCheck: false,
            isEvidence: false,
            isError: false,
            useChatGPT: false,
            initialQuery: '',
            articleData: {},
            sentenceData: {},
            showSourceInfo: false,
            selectedClaimIndex: 0,
            selectedSentence: undefined,
            selectedClaim: {},
            cbMessage: '',
            gptUUID: ''
        }
        this.pollingDelay = 2000;
        this.invokeEndpoint = `${process.env.REACT_APP_API_URL}invoke`;
        this.handleInitialQuery = this.handleInitialQuery.bind(this);
        this.handleSentenceClick = this.handleSentenceClick.bind(this);
        this.handleEvidenceClick = this.handleEvidenceClick.bind(this);
        this.handleReturnToArticle = this.handleReturnToArticle.bind(this);
        this.handleCloseEvidence = this.handleCloseEvidence.bind(this);
        this.handleNextSentenceClick = this.handleNextSentenceClick.bind(this);
        this.handlePrevSentenceClick = this.handlePrevSentenceClick.bind(this);
        this.handleNextClaimClick = this.handleNextClaimClick.bind(this);
        this.handlePrevClaimClick = this.handlePrevClaimClick.bind(this);
        this.handleSentenceClaimCheck = this.handleSentenceClaimCheck.bind(this);
        this.handleShowSourceInfo = this.handleShowSourceInfo.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleEvidenceItemCountIncrement = this.handleEvidenceItemCountIncrement.bind(this);
        this.handleGPTQuery = this.handleGPTQuery.bind(this);
        this.handleGptMessageKeyDown = this.handleGptMessageKeyDown.bind(this);
        this.refreshUserTokens = this.refreshUserTokens.bind(this);
    }



    gptMessage = React.createRef();

    componentDidMount() {
        const websiteURL = localStorage.getItem('websiteURL');
        const useChatGPT = (localStorage.getItem('useChatGPT') === "true");
        //let whispUserAuth = sessionStorage.getItem('whispUserAuth');

        this.setState({
            initialQuery: websiteURL,
            useChatGPT: useChatGPT
        })

        this.handleInitialQuery();
    }

    refreshUserTokens(callBackFunction) {
        let userAuth = JSON.parse( sessionStorage.getItem('whispUserAuth') );
        let postData = {
            RefreshToken: userAuth.refresh_token
        }
        fetch(`${process.env.REACT_APP_API_URL}refresh`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "omit",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Origin": process.env.REACT_APP_CALLBACK_URL,
                "Access-Control-Request-Method": "POST",
            },
            redirect: "follow",
            referrer: "no-referrer",
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                }
            })
            .then(data => {
                if (data.errorType === undefined) {
                    let authResult = {...userAuth,
                        isLoading: false,
                        id_token: data.id_token,
                        access_token: data.access_token,
                        expires_in: data.expires_in,
                        token_type: data.token_type
                    }
                    sessionStorage.setItem('whispUserAuth', JSON.stringify(authResult));
                    if (callBackFunction !== null)
                        callBackFunction();

                } else {
                    console.log(data);
                    console.log("errorType: " + data.ConnectionError);
                    console.log("errorMessage" + data.errorMessage);
                }
            })
            .catch(error => console.error(`Fetch Error =\n`, error));
    }

    async handleInitialQuery(gptMessageOveride = undefined) {
        const gptMessage = (gptMessageOveride !== undefined)? gptMessageOveride : localStorage.getItem('websiteURL');
        const userAuth = JSON.parse( sessionStorage.getItem('whispUserAuth') );
        let postData = {
            "RequestType": "ChatGPT",
            "RequestBody": {
                "Stage": "Initial",
                "Message": gptMessage
            }
        }

        let response = await fetch(this.invokeEndpoint, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "omit",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Origin": process.env.REACT_APP_CALLBACK_URL,
                "Access-Control-Request-Method": "POST",
                "Authorization" : userAuth.id_token
            },
            redirect: "follow",
            referrer: "no-referrer",
            body: JSON.stringify(postData)
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else if (response.status === 401) {
                    // passing in the current function so that the user action can continue seamlessly
                    this.refreshUserTokens(this.handleInitialQuery(gptMessage));
                }
            })
            .then(data => {
                // statusCode 202 is returned while the API is still processing the request
                if (data.statusCode !== undefined && data.statusCode === 202) {
                    // wait 2 seconds before calling this function again
                    setTimeout(() => {
                        this.handleInitialQuery(gptMessage);
                    }, this.pollingDelay)
                } else {
                    if (data !== undefined) {
                        this.setState({
                            articleData: data,
                            sentenceData: data.message_history,
                            gptUUID: data.uuid,
                            isLoading: false,
                            isError: false
                        });
                    } else {
                        // API Timeout
                        console.log('data is undefined. Likely caused by API time out');
                        this.setState({
                            isError: true
                        })
                    }
                }
            })
            .catch(error => {
                console.log('Something went wrong. Likely caused by API time out');
                console.log(error);
                this.setState({
                    isError: true
                })
            });
    }

    handleReturnToArticle() {
        this.setState({
            isFactCheck: false,
            selectedSentence: {}
        });
    }

    handleSentenceClick (sentence) {
        this.setState({
            isFactCheck: false,
            isEvidence: false
        });
        this.setState({
            selectedSentence: sentence
        });
        this.setState({
            isFactCheck: true
        });
    }

    handleEvidenceClick (selectedClaim, selectedClaimIndex) {
        this.setState({
            isEvidence: true,
            selectedClaim: selectedClaim,
            selectedClaimIndex: selectedClaimIndex
        });
    }

    handleCloseEvidence () {
        this.setState({
            isEvidence: false,
            selectedClaim: {},
            selectedClaimIndex: 0
        });
    }

    handlePrevSentenceClick () {
        let index = this.state.articleData.analysis.findIndex( (sentence) => sentence.sid ===  this.state.selectedSentence.sid );
        let prevItem = this.state.articleData.analysis[(index - 1)];
        this.setState({
            isFactCheck: false
        });
        this.setState({
            selectedSentence: prevItem,
        });
        this.setState({
            isFactCheck: true
        });
    }

    handleNextSentenceClick () {
        let index = this.state.articleData.analysis.findIndex( (sentence) => sentence.sid ===  this.state.selectedSentence.sid );
        let nextItem = this.state.articleData.analysis[(index + 1)];
        this.setState({
            isFactCheck: false,
            isEvidence: false
        });
        this.setState({
            selectedSentence: nextItem,
            isFactCheck: true
        });
    }

    handleEvidenceItemCountIncrement() {
        let currentEvidenceItemCount = this.state.selectedClaimIndex
        this.setState({
            selectedClaimIndex: currentEvidenceItemCount++
        })
    }

    handleNextClaimClick() {
        let nextClaimIndex = this.state.selectedClaimIndex;
        if (this.state.selectedSentence.claims.length > (nextClaimIndex + 1)) {
            // check if next claim has evidence
            if (this.state.selectedSentence.claims[nextClaimIndex + 1].evidence !== null &&
                ( (this.state.selectedSentence.claims[nextClaimIndex + 1].evidence.n_entail + this.state.selectedSentence.claims[nextClaimIndex + 1].evidence.n_refute) > 0 )
            ) {
                nextClaimIndex = (nextClaimIndex + 1);
            } else {
                // look for next claim with evidence
                for (let i=nextClaimIndex; i < this.state.selectedSentence.claims.length; i++) {
                    if (this.state.selectedSentence.claims[i].evidence !== null &&
                        ( (this.state.selectedSentence.claims[i].evidence.n_entail + this.state.selectedSentence.claims[i].evidence.n_refute) > 0 )
                    ) {
                        nextClaimIndex = i;
                        break;
                    }
                }
            }
        }

        this.setState({
            isEvidence: false,
            isLoading: true,
            selectedClaim: this.state.selectedSentence.claims[nextClaimIndex],
            selectedClaimIndex: nextClaimIndex
        })

        this.setState({
            isEvidence: true,
            isLoading: false
        })

    }

    handlePrevClaimClick() {
        let prevClaimIndex = this.state.selectedClaimIndex;
        if (this.state.selectedSentence.claims.length > (prevClaimIndex + 1)) {
            // check if next claim has evidence
            if (this.state.selectedSentence.claims[prevClaimIndex - 1].evidence !== null &&
                ( (this.state.selectedSentence.claims[prevClaimIndex - 1].evidence.n_entail + this.state.selectedSentence.claims[prevClaimIndex - 1].evidence.n_refute) > 0 )
            ) {
                prevClaimIndex = (prevClaimIndex - 1);
            } else {
                // look for next claim with evidence
                for (let i=this.state.selectedSentence.claims.length; (i > 0 && i > prevClaimIndex); i--) {
                    if (this.state.selectedSentence.claims[i].evidence !== null &&
                        ( (this.state.selectedSentence.claims[i].evidence.n_entail + this.state.selectedSentence.claims[i].evidence.n_refute) > 0 )
                    ) {
                        prevClaimIndex = i;
                        break;
                    }
                }
            }
        }

        this.setState({
            isEvidence: false,
            isLoading: true,
            selectedClaim: this.state.selectedSentence.claims[prevClaimIndex],
            selectedClaimIndex: prevClaimIndex
        })

        this.setState({
            isEvidence: true,
            isLoading: false
        })

    }

    handleSentenceClaimCheck(sentenceItem) {
        if (sentenceItem !== undefined) {
            let sentenceClasses = '';

            // check if selected or not
            if (this.state.selectedSentence !== undefined &&
                this.state.selectedSentence.text === sentenceItem.text) {
                sentenceClasses += "sentence-item selected";
            } else {
                sentenceClasses += "sentence-item";
            }

            // Calculate how many Positive and Negative claims based on the evidence entail,refute
            let totalPositiveClaims = 0;
            let totalNegativeClaims = 0;

            if (sentenceItem.claims !== null) {
                totalPositiveClaims = sentenceItem.claims.filter(claim => {
                    if (claim.evidence !== null && claim.evidence.n_entail > claim.evidence.n_refute) {
                        return claim;
                    } else {
                        return null;
                    }
                }).length;
                totalNegativeClaims = sentenceItem.claims.filter(claim => {
                    if (claim.evidence !== null && claim.evidence.n_entail < claim.evidence.n_refute) {
                        return claim;
                    } else {
                        return null;
                    }
                }).length;
            }

            // mostly entail claims
            if (totalPositiveClaims > totalNegativeClaims) {
                sentenceClasses += " mostly-entail ";
            }
            // mostly refute claims
            if (totalPositiveClaims < totalNegativeClaims) {
                sentenceClasses += " mostly-refute ";
            }
            // passes the CFS check but has no claims with evidence
            if (totalPositiveClaims === 0 && totalNegativeClaims === 0) {
                sentenceClasses += " unimportant ";
            }

            return sentenceClasses;
        } else {
            return '';
        }
    }

    handleShowSourceInfo() {
        this.setState({
            showSourceInfo: !this.state.showSourceInfo
        })
    }
    handleEdit() {
        //alert('Handle Edit functionality')
    }

    async handleGPTQuery(gptMessageOveride = undefined) {
        const userAuth = JSON.parse( sessionStorage.getItem('whispUserAuth') );
        let gptMessage = (gptMessageOveride !== undefined)? gptMessageOveride : this.gptMessage.current.value;

        this.setState({
            isLoading: true
        });
        // check if message has been entered
        if (gptMessage !== '') {

            let postData = {
                "RequestType": "ChatGPT",
                "RequestBody": {
                    "Stage": "FollowOn",
                    "ChatId": this.state.gptUUID,
                    "Message": gptMessage
                }
            }


            let response = await fetch(this.invokeEndpoint, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "omit",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "Origin": process.env.REACT_APP_CALLBACK_URL,
                    "Access-Control-Request-Method": "POST",
                    "Authorization" : userAuth.id_token
                },
                redirect: "follow",
                referrer: "no-referrer",
                body: JSON.stringify(postData)
            })
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else if (response.status === 401) {
                        // passing in the current function so that the user action can continue seamlessly
                        this.refreshUserTokens(this.handleGPTQuery(gptMessage));
                    }
                })
                .then(data => {
                    if (data.statusCode === 202) {
                        setTimeout(() => {
                            this.handleGPTQuery(gptMessage);
                        }, this.pollingDelay)
                    }
                    else {
                        if (data !== undefined) {
                            this.setState({
                                articleData: data,
                                sentenceData: data.message_history,
                                gptUUID: data.uuid,
                                isLoading: false,
                                isError: false
                            });

                        } else {
                            // API Timeout
                            console.log('data is undefined. Likely caused by API time out');
                            this.setState({
                                isError: true
                            })
                        }
                    }
                })
                .catch(error => {
                    console.log('data is undefined. Likely caused by API time out');
                    console.log(error);
                    this.setState({
                        isError: true
                    })
                });

        }
    }

    handleGptMessageKeyDown(e) {
        if (e.key === "Enter") {
            this.handleGPTQuery(undefined);
        }
    }

    render() {
        const {isLoading, useChatGPT, articleData, sentenceData, isFactCheck, selectedSentence, isEvidence, selectedClaim, showSourceInfo} = this.state;

        if (!isLoading) {
            return (
                <Generic>
                    <div className="article-component">
                    <div className={(useChatGPT)? "article-data layout-chatgpt" : "article-data"}>

                        <div className="content">

                            <div className="left-column ">
                                <Header showNewSearch={true} />
                                <div className="main-content">

                                    <div className="action-bar">
                                        <div className="top-bar">
                                            <div className="article-logo">
                                                {(articleData.site_logo !== null && articleData.site_logo !== undefined)? (
                                                    <img src={articleData.site_logo} alt={articleData.site_name}/>
                                                ) : null}
                                            </div>
                                            <div onClick={this.handleShowSourceInfo} className="source-info-toggle">
                                                <div>Source Info</div>
                                                {(showSourceInfo)? (
                                                    <img src="/images/icon-collapse.svg" alt="Hide Source info" loading="lazy" width="24" height="22"/>
                                                ): (
                                                    <img src="/images/icon-expand.svg" alt="Show Source info" loading="lazy" width="24" height="22"/>
                                                )}
                                            </div>
                                        </div>
                                        {(showSourceInfo)? (
                                        <div className="source-info">
                                            {(articleData.date_published !== undefined)? (
                                                <div className="si-item">
                                                    <div className="si-title">Published:</div>
                                                    {articleData.date_published}
                                                </div>
                                            ) :  null}

                                            {(articleData.authors !== undefined)? (
                                                <div className="si-item">
                                                    <div className="si-title">Author:</div>
                                                    {articleData.authors}
                                                </div>
                                            ) : null}

                                            {(articleData.site_name !== undefined)? (
                                                <div className="si-item">
                                                    <div className="si-title">Source name &amp; URL:</div>
                                                    {(articleData.url!== undefined)? (
                                                        <a href={articleData.url} target="_blank" rel="nofollow noreferrer">{articleData.site_name}</a>
                                                    ) : (
                                                        articleData.site_name
                                                    ) }
                                                </div>
                                            ) : null}

                                            {(articleData.description !== undefined && articleData.description.content !== undefined)? (
                                                <div className="si-item description">
                                                    <div className="si-title">Description:</div>
                                                    {articleData.description.content}
                                                </div>
                                            ) : null}
                                        </div>
                                        ) : null }
                                    </div>

                                    <div className="edit-bar">

                                    </div>

                                    <div className="sticky-scroll-box">
                                        <div className="article-title">
                                            {(articleData.title !== null && articleData.title !== undefined)? articleData.title.content : null}
                                        </div>
                                        <div className="article-content">
                                            {sentenceData.map(sentenceItem => (
                                                <>
                                                    {(sentenceItem.role === "user")? (
                                                        <>
                                                            <div key={sentenceItem.content.content}>
                                                                <span className={"sentence-item"}>
                                                                    {sentenceItem.content.content}
                                                                </span>
                                                            </div>

                                                        </>
                                                    ) : (
                                                    <div key={sentenceItem.content.content}>
                                                        {(sentenceItem.analysis.map(analysisItem => (
                                                            <span onClick={() => this.handleSentenceClick(analysisItem)}
                                                                  className={this.handleSentenceClaimCheck(analysisItem)}
                                                                  key={analysisItem.sid}>
                                                                {analysisItem.text}
                                                            </span>
                                                        )))}
                                                    </div>
                                                    )}
                                                </>
                                            ))}
                                        </div>

                                        <div className="chatbox">
                                            <textarea ref={this.gptMessage} onKeyDown={this.handleGptMessageKeyDown} className="cb-message" name="message" id="message" cols="30" rows="2" placeholder={"Enter your question"} ></textarea>
                                            <div className="submit-message" onClick={() => {this.handleGPTQuery(undefined)}}>Send</div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            { (isFactCheck === true && isEvidence === false) ? (
                                <div className="right-column factcheck-container">
                                    { (isFactCheck === true && useChatGPT === false) ? (
                                    <div className="actions">
                                        <div className="sentence-toggle">
                                            <div className="btn-container">
                                                    { (sentenceData.findIndex( (sentence) => sentence.sid ===  this.state.selectedSentence.sid ) > 0) ? (
                                                        <div className="btn prev enabled" onClick={this.handlePrevSentenceClick}>
                                                            <img src="/images/arrow-small-back-white.svg" width="12" height="20" loading="lazy" alt="Back arrow" />
                                                        </div>
                                                    ) : (
                                                        <div className="btn prev" >
                                                            <img src="/images/arrow-small-back-white-disabled.svg" width="12" height="20" loading="lazy" alt="Back arrow disabled" />
                                                        </div>
                                                    ) }
                                                    <span>Sentences</span>
                                                    { (sentenceData.findIndex( (sentence) => sentence.sid ===  this.state.selectedSentence.sid ) < (sentenceData.length - 1) )  ? (
                                                        <div className="btn next enabled" onClick={this.handleNextSentenceClick}>
                                                            <img src="/images/arrow-small-next-white.svg" width="12" height="20" loading="lazy" alt="Next arrow" />
                                                        </div>
                                                    ) : (
                                                        <div className="btn next">
                                                            <img src="/images/arrow_small_next_disabled.svg" width="12" height="20" loading="lazy" alt="Next arrow disabled" />
                                                        </div>
                                                    ) }
                                                </div>
                                        </div>
                                    </div>
                                    ) : null }
                                    <FactCheck
                                        factCheckItem={selectedSentence}
                                        handleEvidenceClick={this.handleEvidenceClick}
                                        handleReturnToArticle={() => this.handleReturnToArticle()}
                                        chatGPTMode={useChatGPT}
                                    />
                                </div>
                            ) : null }

                            { isEvidence ? (
                                <div className="right-column evidence-container">
                                    <Evidence
                                        evidenceItem={selectedClaim.evidence}
                                        selectedClaim={selectedClaim}
                                        handleNextClaimClick={this.handleNextClaimClick}
                                        handlePrevClaimClick={this.handlePrevClaimClick}
                                        handleCloseEvidence={this.handleCloseEvidence}
                                        handlePrevSentenceClick={this.handlePrevSentenceClick}
                                        handleNextSentenceClick={this.handleNextSentenceClick}
                                        selectedClaimIndex={(this.state.selectedClaimIndex + 1)}
                                        totalClaims={this.state.selectedSentence.claims.length}
                                        currentSentenceNumber={ (sentenceData.findIndex( (sentence) => sentence.sid ===  this.state.selectedSentence.sid )) }
                                        totalSentences={ (sentenceData.length - 1) }
                                        chatGPTMode={useChatGPT}
                                    />
                                </div>
                            ) : null }

                        </div>
                    </div>
                </div>
                </Generic>
            )
        } else {
            return (
                <Generic>
                    {(this.state.isError)? (
                        <div className="error-modal">
                            <div className="em-inner">
                                We are currently experiencing higher demand than usual.<br />
                                Please try again in a few minutes.<br />
                                <Link to={"/"} >Return to the home page</Link>
                            </div>
                        </div>
                    ) : null }
                    <LoadingPage />
                </Generic>
            )
        }
    }
}
export default Conversation;
