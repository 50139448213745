import React from "react";
import './footer.css';
import {Link} from "react-router-dom";
class Footer extends React.Component {
    render() {
        return (
            <div className="footer">
                <div className="inner">

                    <div className="left">
                        <div className="logo-container">
                            <img src="/images/Whisp-Logo-white.svg" alt="Whisp logo" width="120" height="29" loading="lazy" />
                        </div>
                        <div className="text">
                            <p>Instant, AI-powered fact-checking and misinformation detection for the internet age.</p>
                        </div>
                    </div>

                    <div className="right">
                        <div className="footer-menu">
                            <div className="col column-1">
                                <ul>
                                    <li><Link to={"/about"}>About</Link></li>
                                    <li><Link to={"/technology"}>Technology</Link></li>
                                </ul>
                            </div>
                            <div className="col column-2">
                                <ul>
                                    <li><Link to={"/contact"}>Contact</Link></li>
                                    <li><Link to={"/newsroom"}>Newsroom</Link></li>
                                </ul>
                            </div>
                            <div className="col column-3">
                                <ul>
                                    <li><Link to={"/privacy-policy"}>Privacy Policy</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="copyright">
                    &copy; 2023 Whisp Limited. All rights reserved • Technologies Patent Pending (PCT App. PCT/IB2022/060498)
                </div>
            </div>
        )
    }
}
export default Footer;
