import React from "react";
import './header.css';
import {Link} from "react-router-dom";
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showNewSearch: false,
            showMenu: false
        }
        this.handleMenuToggle = this.handleMenuToggle.bind(this);
    }
    componentDidMount() {
        if (this.props.showNewSearch === true) {
            this.setState({
                showNewSearch: true
            })
        }
    }

    handleMenuToggle() {
        let currentMenuState = this.state.showMenu;
        this.setState({
            showMenu: !currentMenuState
        })
    }

    render() {
        return (
            <>
                <div className={(this.state.showMenu)? "menu-slide-out open" : "menu-slide-out"}>
                    <div className="mso-inner">

                        <div className="top-bar">
                            <div className="logo">
                                <Link to="/">
                                    <img src="/images/Whisp-Logo-white.svg" alt="Whisp logo" width="120" height="29" loading="lazy" />
                                </Link>
                            </div>
                            <div className="close-menu" onClick={this.handleMenuToggle}>
                                <img className="close-icon" src="/images/icon-new-search-white.svg" alt="Close Menu" loading="lazy" height="28" width="28" />
                            </div>
                        </div>

                        <ul>
                            <li><Link to={"/about"}>About</Link></li>
                            <li><Link to={"/technology"}>Technology</Link></li>
                            <li><Link to={"/contact"}>Contact</Link></li>
                            <li><Link to={"/newsroom"}>Newsroom</Link></li>
                            <li><Link to={"/privacy-policy"}>Privacy Policy</Link></li>
                        </ul>
                    </div>
                </div>

                <div className="header">
                    <div className="logo-container">
                        <Link to="/">
                            <img src="/images/Whisp-Logo-white.svg" alt="Whisp logo" width="120" height="29" loading="lazy" />
                        </Link>
                    </div>
                    <div className="action-container">
                        { (this.state.showNewSearch)? (
                            <Link to="/">
                                <img className="account-new-search" src="/images/icon-new-search-white.svg" alt="New Search" loading="lazy" height="28" width="28" />
                            </Link>
                        ) : null }
                        <Link to="/account/"><img className="account-icon" src="/images/icon-account-white.svg" alt="Account" loading="lazy" height="28" width="28" /></Link>
                        <img className="menu-icon" src="/images/icon-burger-menu-white.svg" alt="Manu" loading="lazy" height="28" width="28" onClick={this.handleMenuToggle} />
                    </div>
                </div>
            </>
        )
    }
}
export default Header;
